<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col><h2>Events</h2></v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ListAll :url="url" :headers="headers" :callbackClick="clickRead"></ListAll>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import ListAll from "../../components/Datatable/ListAll.vue";
export default {
  components: {
    ListAll,
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[apiGroup]
    apiGroup: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    group:null,
    //EOC
    gameUrl: null,
    url: null,
    headers: [],
  }),
  created() {
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "Events",
        to: {
          name: "PageEducatorInstitutionEvent",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
    ]
    //
    this.headers = [
      { text: "Poster", value: "eventGamePoster" },
      { text: "Name", value: "name" },
      { text: "Date", value: "eventDate" },
      { text: "Status", value: "eventStatus" },
      { text: "", value: "eventAction", align: "end" },
    ];
    //

    this.url = this.api.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/main/event";

    this.api.method = "GET";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      return resp;
    };
    //BOC
    this.apiGroup.method = "GET";
    this.apiGroup.url = `${this.$api.servers.sso}/api/v1/${this.$_getLocale()}/educator/institution/${this.$route.params.id}/group/getCode`;

    this.apiGroup.callbackReset = () => {
      this.apiGroup.isLoading = true;
      this.apiGroup.isError = false;
    };

    this.apiGroup.callbackError = (e) => {
      this.apiGroup.isLoading = false;
      this.apiGroup.isError = true;
      this.apiGroup.error = e;
      alert(e);
    };

    this.apiGroup.callbackSuccess = (resp) => {
      this.apiGroup.isLoading = false;
      this.group = resp;
    };
    //EOC
  },
  mounted() {
    this.$api.fetch(this.apiGroup);
    this.$api.fetch(this.api);
  },
  methods: {
    clickRead(item) {
      var routeData
      if(this.$moment() > this.$moment(item.timestampEnd)){
        routeData = this.$router.resolve({
        name: "PageMainEventFinisher",
        params: { eventCode: item.code, schoolCode: this.group.code },
      });
      }else{
        routeData = this.$router.resolve({
        name: "PageMainEventScoreboard",
        query:{
          c:this.group.code,
        },
        params: { eventCode: item.code },
      });
      }
      
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>